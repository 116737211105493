<template>
  <van-skeleton v-if="loading" row="6" />
  <div v-else class="page">
    <div class="head">
      <div class="title">
        <p>{{order_info.state_mark}}</p>
      </div>
      <div v-if="order_info.state===3" class="about">
        <div class="l2">
          <p>{{order_info.title}}</p>
        </div>
        <div class="l2">
          <van-count-down :time="order_info.seconds * 1000" />
        </div>
      </div>
      <div v-else class="about">
        <div class="l1">
          <p>{{order_info.title}}</p>
        </div>
      </div>
    </div>

<!--    <p class="tips" v-if="order_info.state===3">上拉刷新订单状态</p>-->

    <div class="mod">
      <p class="title">商品：{{order_info.title}}</p>
      <p>价格{{order_info.real_pay}}元</p>
      <p>下单时间：{{order_info.create_time}}</p>
    </div>

    <p  v-if="order_info.state === -1 || order_info.state === 3" id="tips">有困惑？<span class="warn" data-value="contact" @click="pull_up">联系我们</span></p>

    <div class="foot" v-if="order_info.state===3">
      <button class="black w1"  @click="to_pay" >付款</button>
    </div>

    <div  class="foot"  v-if="order_info.state === 4  || order_info.state === 5 || order_info.state === 8 ||order_info.state === 9">
      <button class="black w1" data-value="index" @click="xml_go_to_only" >去首页看看</button>
    </div>

    <van-popup v-model:show="show.contact" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Contact></Contact>
    </van-popup>

    <van-popup v-model:show="show.ask" round get-container="body" @close="on_close">
      <div id="ask">
        <p class="tips">请确认微信支付是否完成</p>
        <p class="a"><button @click="re_fresh" class="in_body green" >已完成支付</button></p>
        <p class="a"><button @click="to_pay" class="in_body vip_color" >遇到些问题，重新支付</button></p>
        <div class="close" @click="on_close"><van-icon name="clear" color="#ccc" size="0.8rem" /></div>
      </div>
    </van-popup>
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>

  </div>
</template>

<script>
import axios from 'axios'
import Utils from '@/utils/utils'
import Contact from '@/components/other/Contact'

export default {
  name: 'order_detail',
  components: {
    Contact
  },
  data () {
    return {
      show: { contact: false, ask: false },
      loading: true,
      disabled: false,
      order_info: {},
      wx_pay_url: ''
    }
  },
  mounted () {
    this.order_detail()
  },
  methods: {
    order_detail () {
      axios.post('/order_detail/', { id: this.$route.query.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.wx_pay_url = res.data.data.wx_pay_url
          const orderInfo = res.data.data.order_info
          orderInfo.create_time = Utils.format_time_easy(orderInfo.create_time)
          this.order_info = orderInfo
          this.loading = false
          // if (this.$route.query.need_ask && this.order_info.state === 3) {
          //   this.show.ask = true
          // }
          if (this.$route.query.new && this.order_info.state === 3) {
            this.show.ask = true
            this.to_pay()
          } else if (this.order_info.state === 3) {
            this.show.ask = true
          }
        })
    },
    to_pay: function (e) {
      const a = document.createElement('a')
      a.setAttribute('reffer', 'www.ssqm.cn')
      a.setAttribute('href', this.wx_pay_url)
      a.click()
    },
    re_fresh () {
      this.show.ask = false
      this.order_detail()
    },
    on_close () {
      this.show = { contact: false, ask: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style>
.van-count-down{
  color: purple;
  font-size: 0.6rem;
}
.mod{
  text-align: left;
  padding: 0.5rem 0.5rem;
}
.mod .title{
  font-weight: bold;
}

#ask{
  padding: 0.8rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.3rem;
  position: relative;
}
#ask .close{
  position: absolute;
  right:0.1rem;
  top:0.1rem;
  width: 0.8rem;
  height: 0.8rem;
}
#ask p.a{
  margin:0.8rem 0.6rem;
}
#ask button{
  font-size: 0.4rem;
}
#ask button{
  font-size: 0.4rem;
}
</style>
